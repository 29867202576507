import gsap from 'gsap';
import $ from '../core/Dom';
import superagent from '../core/request';

import FadeIn from './FadeIn';

export default el => {

    const $el = $(el);

    const select = $el.find('.archive__category').get(0);
    const $options = $(select).find('option');
    const $container = $el.find('.archive__list');
    const $links = $el.find('.archive__category-list.-link');
    const originalItems = $container.html();
    const allUrl = $options.get(0).value;

    const { jQuery } = window;

    let isLoading = false;
    let xhr = null;
    let sumo;

    const hideProjects = () => {
        gsap.to($container.get(0), { duration: 0.15, opacity: 0, ease: 'Sine.easeOut' });
    };

    const showProjects = () => {
        isLoading = false;
        gsap.to($container.get(0), { duration: 0.45, opacity: 1, delay: 0.3, ease: 'Sine.easeOut' });
    };

    const showAll = () => {
        gsap.to($container.get(0), {
            duration: 0.25,
            opacity: 0,
            ease: 'Sine.easeOut',
            onComplete() {
                $container.html(originalItems);
                //picturefill();
                showProjects();
            }
        });
    };

    const setActiveLink = href => {
        const $oldLink = $el.find('.-isSelected');
        const $link = $el.find(`.archive__category-list.-link[data-url="${href}"]`).eq(0);
        $oldLink.removeClass('-isSelected');
        $link.addClass('-isSelected');
    };

    const loading = (item, delay) => {
        gsap.timeline({
            onComplete() {
                if (isLoading) {
                    loading(item, 0.5);
                }
            }
        })
            .to(item, {
                duration: 0.5,
                rotationX: 360,
                ease: 'Quad.easeInOut',
                delay
            })
            .set(item, { rotationX: 0 });
    };

    const update = () => {

        isLoading = true;

        if (xhr) {
            xhr.abort();
            xhr = null;
        }

        const category = $(select).val();

        setActiveLink(category);

        if (sumo) {
            sumo.sumo.reload();
        }

        if (category === allUrl) {
            showAll();
        } else {

            hideProjects();

            xhr = superagent.get(`/${category}`);

            xhr
                .then(({ status, text: html }) => {
                    if (status !== 200 || !html) {
                        throw new Error();
                    }
                    $container.html(html);
                    // picturefill();
                    showProjects();
                })
                .catch(error => {
                    console.error(error);
                })
                .then(() => {
                    xhr = null;
                });
        }
    };

    const linkClick = e => {
        e.preventDefault();
        const { url } = e.target.dataset;
        const $select = jQuery ? jQuery(select) : $(select);
        $select.val(url);
        update();
    };

    const init = () => {
        if (jQuery) {
            sumo = jQuery(select).SumoSelect();
            jQuery(select).on('change', update);
        } else {
            $(select).on('change', update);
        }
        $links.on('click', linkClick);
        FadeIn(el).init();
    };

    const destroy = () => {
        $links.off('change');
        if (jQuery) {
            jQuery(select).off('change');
        } else {
            $(select).off('change');
        }
        if (sumo) {
            sumo.sumo.unload();
        }
    };

    return {
        init,
        destroy
    };

};
