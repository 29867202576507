import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const OFFSET_TOP = 50;
const OFFSET_BUFFER = 10;

export default el => {

    const $el = $(el);

    const hasTransparency = $el.hasClass('-transparent');

    const $body = $('body');
    const logoBig = $el.find('.logo__big').get(0);
    const logoSmall = $el.find('.logo__small').get(0);

    let isHidden = false;
    let isTransparent = !!hasTransparency;
    let isSmall = false;
    let prevScrollTop = null;

    const toggleSize = small => {
        if (small && isSmall) {
            $el.addClass('-grow');
            $el.removeClass('-small');
            gsap.to(logoBig, { rotationX: 0, duration: 0.35 });
            gsap.to(logoSmall, { rotationX: 180, duration: 0.35 });
            isSmall = false;
        } else if (!small && !isSmall) {
            $el.addClass('-small');
            $el.removeClass('-grow');
            gsap.to(logoSmall, { rotationX: 0, duration: 0.35 });
            gsap.to(logoBig, { rotationX: 180, duration: 0.35 });
            isSmall = true;
        }
    };

    const toggleTransparency = transparent => {
        if (!hasTransparency) {
            return;
        }
        if (transparent && !isTransparent) {
            gsap.to(el, {
                color: '#ffffff',
                backgroundColor: 'rgba(255, 255, 255, 0.0001)',
                duration: 0.35,
                ease: 'Sine.easeOut'
            });
        } else if (!transparent && isTransparent) {
            gsap.set(el, { color: '#231F20', backgroundColor: 'rgba(255, 255, 255, 0.95)' });
        }
        isTransparent = transparent;
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        const { scrollTop } = Viewport;
        toggleTransparency(scrollTop < OFFSET_TOP);
        toggleSize(scrollTop < OFFSET_TOP);
        gsap.to(el, { yPercent: 0, duration: 0.35, ease: 'Sine.easeOut' });
    };

    function hide() {
        if (isHidden) {
            return;
        }
        isHidden = true;
        gsap.to(el, {
            yPercent: -100,
            duration: 0.35,
            ease: 'Sine.easeOut',
            onComplete: toggleTransparency,
            onCompleteParams: [false]
        });
    }

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (prevScrollTop !== null && Math.abs(scrollTop - prevScrollTop) < OFFSET_BUFFER) {
            return;
        }
        const isScrollingUp = scrollTop < prevScrollTop;
        if (isScrollingUp) {
            toggleTransparency(scrollTop < OFFSET_TOP);
            toggleSize(scrollTop < OFFSET_TOP);
            show();
        } else if (scrollTop > OFFSET_TOP) {
            hide();
            toggleSize(!isSmall);
        }
        prevScrollTop = scrollTop;
    };

    const init = () => {
        Viewport.on('scroll', onScroll);
        $el.find('a').on('focusin', show);
        $body.addClass('-fixed-header');
        $el.addClass('-fixed');
    };

    const destroy = () => {
        $el.find('a').off('focusin', show);
        Viewport.off('scroll', onScroll);
    };

    return {
        init,
        destroy
    };

};
