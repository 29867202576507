import $ from '../core/Dom';
import { scrollTo } from '../lib/helpers';

export default el => {

    const $el = $(el);

    const init = () => {
        $el.on('click', e => {
            e.preventDefault();
            scrollTo(0);
        });
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
