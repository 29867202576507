import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

import ScrollReveal from '../lib/ScrollReveal';

export default el => {

    const $el = $(el);
    const $revealCustomElements = $el.find('[data-sr-cstm]');

    const init = () => {
        window.sr = new ScrollReveal({
            viewFactor: 0.01,
            scale: 1,
            distance: '0',
            easing: 'ease-out',
            duration: 1000,
            opacity: 0.1
        });
        $revealCustomElements.each(node => {
            window.sr.reveal(node, {
                opacity: parseFloat($(node).data('sr-cstm'))
            });
        });
        window.sr.reveal('[data-sr]');
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
