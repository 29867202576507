import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import {
    MAIN_MENU_CLOSE,
    MAIN_MENU_OPEN,
    SEARCH_BAR_CLEAR,
    SEARCH_BAR_HIDE,
    SEARCH_BAR_SHOW,
    TOGGLE_SEARCH_MODAL_STATE
} from '../lib/events';

const MOBILE_MENU_BREAKPOINT = 960;

export default el => {

    const $el = $(el);

    const $body = $('body');
    const $html = $('html');
    const viewport = document.querySelector('meta[name="viewport"]');

    const $btnMenuOpen = $('.open-menu');
    const $btnMenuClose = $el.find('.close-menu');
    const $btnSearchBarShow = $('.open-search');
    const $btnSearchBarHide = $('.search__close--bar');
    const $menuItems = $el.find('.menu__item');
    const $searchInput = $('.search__input');
    const $pageHeader = $('.page-header');
    const $pageHeaderWrapper = $('.page-header__wrapper');

    const menuList = $el.find('.menu__items').get(0);
    const searchBar = $('.search').get(0);

    let { size: currentBreakpoint } = Viewport.breakpoint;
    let searchBarIsShowing = false;
    let searchModalIsOpen = false;
    let menuIsOpen = false;
    let scrollPosOnMenuOpen;

    const setSearchBarStyle = () => {
        gsap.killTweensOf(searchBar);
        gsap.set(searchBar, { clearProps: 'all' });
        if (currentBreakpoint >= MOBILE_MENU_BREAKPOINT) {
            gsap.set(searchBar, { top: '-152px', bottom: 'auto', display: 'block', position: '' });
        }
    };

    const showSearchBar = () => {
        Dispatch.emit(SEARCH_BAR_SHOW);
    };

    const onShowSearchBar = () => {

        gsap.to(searchBar, {
            top: '0px',
            bottom: 'auto',
            position: '',
            ease: 'Quart.easeInOut',
            duration: 0.5,
            onComplete() {
                searchBarIsShowing = !searchBarIsShowing;
            }
        });

        $searchInput.get(0).focus();

        Viewport.releaseTabbing();

        $pageHeaderWrapper.attr('tabIndex', '-1').find('a, input, button, select, textarea').attr('tabIndex', '-1');

        Viewport.enableTabbingOnElement(searchBar);

    };

    const hideSearchBar = () => {
        Dispatch.emit(SEARCH_BAR_HIDE);
    };

    const clearSearchBar = () => {
        Dispatch.emit(SEARCH_BAR_CLEAR);
    };

    const onHideSearchBar = () => {

        searchBarIsShowing = !searchBarIsShowing;

        gsap.to(searchBar, {
            duration: 0.5,
            top: '-152px',
            bottom: 'auto',
            position: '',
            ease: 'Quart.easeInOut',
            onComplete() {
                clearSearchBar();
            }
        });

        if (!searchModalIsOpen) {
            Viewport.releaseTabbing();
            Viewport.enableTabbingOnElement(el);
        }
    };

    const onClearSearchBar = () => {
        $searchInput.val('');
    };

    const closeMenuOnResize = () => {

        if (!menuIsOpen) {
            return;
        }

        menuIsOpen = false;

        $html.removeClass('-has-open-menu');
        $body.get(0).scrollTop = scrollPosOnMenuOpen + 100;

        setTimeout(() => {
            $body.get(0).scrollTop = scrollPosOnMenuOpen;
        },100);

        $body.css({ overflow: '' });
        $btnMenuClose.css({ marginRight: '' });

        gsap.set([el, searchBar].concat($menuItems.get()), { clearProps: 'all' });

        $pageHeader.removeClass('hasOpenMenu');

        Viewport.releaseTabbing($btnMenuOpen.get(0));
    };

    const onBreakpoint = () => {
        const oldBreakpoint = currentBreakpoint;
        const { size: newBreakpoint } = Viewport.breakpoint;
        currentBreakpoint = newBreakpoint;
        if (oldBreakpoint < 960 && currentBreakpoint >= 960) {
            if (menuIsOpen) {
                closeMenuOnResize();
            }
            setSearchBarStyle();
            Viewport.enableTabbingOnElement(el);
        } else if (oldBreakpoint >= 960 && currentBreakpoint < 960) {
            setSearchBarStyle();
            Viewport.disableTabbingOnElement(el);
        }
    };

    const closeMenu = () => {
        Dispatch.emit(MAIN_MENU_CLOSE);
    };

    const onCloseMenu = () => {

        if (!menuIsOpen) {
            return;
        }

        menuIsOpen = false;

        let delay = 0;

        if (currentBreakpoint < MOBILE_MENU_BREAKPOINT) {
            $html.removeClass('-has-open-menu');
            $body.get(0).scrollTop = scrollPosOnMenuOpen + 100;
            setTimeout(() => {
                $body.get(0).scrollTop = scrollPosOnMenuOpen;
            },100);
        }

        $body.css({ overflow: '' });
        $btnMenuClose.css({ marginRight: '' });

        $menuItems.each((item, i) => {
            delay += (i * 0.025);
            gsap.timeline()
                .set(item, { opacity: 1, x: 0 }, 0)
                .to(item, { x: 125, duration: 0.2, ease: 'Back.easeIn' }, delay)
                .to(item, { opacity: 0, duration: 0.2, ease: 'Linear.easeOut' }, delay);
        });

        gsap.timeline({
            delay,
            onComplete() {
                $pageHeader.removeClass('hasOpenMenu');
            }
        })
            .to(searchBar, { duration: 0.2, opacity: 0, ease: 'Linear.easeOut' })
            .set(searchBar, { clearProps: 'all' })
            .to(el, {
                duration: 0.25,
                delay,
                opacity: 0,
                ease: 'Sine.easeOut'
            })
            .set([el].concat($menuItems.get()), { clearProps: 'all' });

        Viewport.releaseTabbing($btnMenuOpen.get(0));
        Viewport.disableTabbingOnElement(el);

    };

    const onOpenMenu = () => {

        if (menuIsOpen) {
            return;
        }

        const { availHeight } = window.screen;

        console.log({ availHeight });

        const widthWithoutScrollFix = $el.width();

        let delay = 0;

        scrollPosOnMenuOpen = Viewport.scrollTop;

        $pageHeader.addClass('hasOpenMenu');

        gsap.timeline({
            onComplete() {
                $body.css({ overflow: 'hidden' });
                const width = $el.width();
                if (width !== widthWithoutScrollFix) {
                    $btnMenuClose.css({ marginRight: width - widthWithoutScrollFix });
                }
                if (currentBreakpoint < MOBILE_MENU_BREAKPOINT) {
                    $html.addClass('-has-open-menu');
                }
            }
        })
            .set(el, { height: '100%', opacity: 0 })
            .to(el, { opacity: 1, duration: 0.1, ease: 'Sine.easeOut' });

        $menuItems.each((item, i) => {
            delay = 0.1 + (i * 0.03);
            gsap.timeline()
                .set(item, { opacity: 0, x: 125 }, 0)
                .to(item, { x: 0, ease: 'Back.easeOut', duration: 0.3 }, delay)
                .to(item, { opacity: 1, ease: 'Linear.easeOut', duration: 0.2 }, delay);
        });

        gsap.timeline({
            onComplete() {
                menuIsOpen = !menuIsOpen;
            }
        })
            .set(searchBar, { display: 'block', opacity: 0, position: 'fixed', left: 0, bottom: 40, top: 'auto' }, 0) // top: availHeight - 200
            .to(searchBar, { opacity: 1, duration: 0.2, ease: 'Linear.easeOut' }, delay + 0.1);

        setTimeout(() => {
            Viewport.lockTabbing(el, $btnMenuClose.get(0));
            Viewport.enableTabbingOnElement($searchInput.get(0));
            $body.focus();
        }, 0);

    };

    const onKeyUp = e => {
        if (menuIsOpen && !searchModalIsOpen && e.keyCode === 27) {
            closeMenu();
        }
        if (searchBarIsShowing && !searchModalIsOpen && e.keyCode === 27) {
            hideSearchBar();
        }
    };

    const toggleSearchModalState = () => {
        searchModalIsOpen = !searchModalIsOpen;
    };

    const openMenu = () => {
        Dispatch.emit(MAIN_MENU_OPEN);
    };

    const fadeMenuItems = () => {
        gsap.to(menuList, { y: -100, opacity: 0, duration: 0.35 });
    };

    const unfadeMenuItems = () => {
        gsap.timeline()
            .to(menuList, { y: 0, opacity: 1, duration: 0.35 })
            .set(menuList, { clearProps: 'all' });
    };

    const init = () => {

        Dispatch.on(MAIN_MENU_OPEN, onOpenMenu);
        Dispatch.on(MAIN_MENU_CLOSE, onCloseMenu);
        Dispatch.on(SEARCH_BAR_SHOW, onShowSearchBar);
        Dispatch.on(SEARCH_BAR_HIDE, onHideSearchBar);
        Dispatch.on(SEARCH_BAR_CLEAR, onClearSearchBar);
        Dispatch.on(TOGGLE_SEARCH_MODAL_STATE, toggleSearchModalState);
        Viewport.on('breakpoint', onBreakpoint);

        onBreakpoint();

        $body.on('keyup', onKeyUp);

        $btnMenuOpen.on('click', e => {
            e.preventDefault();
            openMenu();
        });

        $btnMenuClose.on('click', e => {
            e.preventDefault();
            closeMenu();
        });

        $btnSearchBarShow.on('click', e => {
            e.preventDefault();
            showSearchBar();
        });

        $btnSearchBarHide.on('click', e => {
            e.preventDefault();
            hideSearchBar();
        });

        $searchInput.on('focusin', () => {
            if (currentBreakpoint >= 960) {
                return;
            }
            fadeMenuItems();
        });

        $searchInput.on('focusout', () => {
            if (currentBreakpoint >= 960) {
                return;
            }
            unfadeMenuItems();
        });

        setSearchBarStyle();

        if (currentBreakpoint < MOBILE_MENU_BREAKPOINT) {
            Viewport.disableTabbingOnElement(el);
        }

    };

    const destroy = () => {
        $body.off('keyup', onKeyUp);
        $btnMenuOpen.off('click');
        $btnMenuClose.off('click');
        $btnSearchBarShow.off('click');
        $btnSearchBarHide.off('click');
        $searchInput.off('focusin');
        $searchInput.off('focusout');
        try {
            Viewport.releaseTabbing();
        } catch (error) {
            console.error(error);
        }
    };

    return {
        init,
        destroy
    };

};
