import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import superagent from '../core/request';
import {
    SEARCH_BAR_CLEAR,
    SEARCH_BAR_HIDE,
    SEARCH_MODAL_CLOSE,
    SEARCH_MODAL_OPEN,
    SEARCH_RESULTS_HIDE,
    SEARCH_RESULTS_SHOW,
    TOGGLE_SEARCH_MODAL_STATE
} from '../lib/events';

const MOBILE_MENU_BREAKPOINT = 960;

export default el => {

    const $el = $(el);

    const $body = $('body');
    const $searchForm = $el.find('.search__form');
    const $searchInput = $el.find('.search__input');
    const $pageHeaderWrapper = $('.page-header__wrapper');

    let $btnCloseSearchModal;
    let $searchModal;
    let $searchResults;

    let searchModalIsOpen = false;
    let xhr = null;

    let { size: currentBreakpoint } = Viewport.breakpoint;

    const getFullWidth = () => {
        $body.css({ overflow: 'hidden' });
        const withOutScrollbar = $searchModal.width();
        $body.css({ overflow: 'visible' });
        return withOutScrollbar;
    };

    const setupModalElements = () => {
        $('.page-header')
            .parent()
            .append('<div class="search__result"></div>');
        $searchModal = $('.search__result');
        $searchModal.append('<a href="#" class="search__close search__close--results-top search__close--results-btn"><span class="visuallyhidden">Close search</span><span class="search__close -x -x1"></span><span class="search__close -x -x2"></span></a>');
        $searchModal.append('<div class="search__result-wrapper"></div>');
        $searchResults = $('.search__result-wrapper');
        $searchModal.append('<a href="#" class="search__close search__close--results search__close--results-btn">Close search</a>');
        $btnCloseSearchModal = $('.search__close--results-btn');
    };

    const onOpenSearchModal = () => {

        if (searchModalIsOpen) {
            return;
        }

        searchModalIsOpen = true;

        Dispatch.emit(TOGGLE_SEARCH_MODAL_STATE);

        gsap.set($searchModal.get(0), { display: 'block' });

        const fullWidth = getFullWidth();

        gsap.timeline()
            .set($searchModal.get(0), {
                width: `${fullWidth}px`,
                scale: 0.6
            })
            .to($searchModal.get(0), {
                scale: 1,
                opacity: 1,
                duration: 0.75,
                ease: 'Quint.easeInOut',
                onComplete() {
                    if (currentBreakpoint >= MOBILE_MENU_BREAKPOINT) {
                        Dispatch.emit(SEARCH_BAR_HIDE);
                    }
                    $body.css({ overflow: 'hidden' });
                    $searchModal.css({ width: '100%' });
                }
            });
    };

    const hideSearchResults = () => {
        Dispatch.emit(SEARCH_RESULTS_HIDE);
    };

    const onHideSearchResults = () => {
        gsap.set($searchResults.get(0), { opacity: 0 });
        gsap.set($btnCloseSearchModal.get(0), { opacity: 0 });
    };

    const showSearchResults = () => {
        Dispatch.emit(SEARCH_RESULTS_SHOW);
    };

    const performSearch = () => {

        // if(Modernizr.touchevents){
        //     setTimeout(function(){
        //         searchInput.blur();
        //     },10);
        // } else {
        //     searchInput.blur();
        // }

        hideSearchResults();

        if (xhr) {
            xhr.abort();
        }

        const url = `/search?query=${$searchInput.val()}`;
        xhr = superagent.get(url);

        xhr
            .then(({ status, text: html }) => {
                if (status !== 200 || !html) {
                    throw new Error();
                }
                $searchResults.html(html);
                showSearchResults();
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                xhr = null;
            });
    };

    const onCloseSearchModal = () => {

        if (!searchModalIsOpen) {
            return;
        }

        searchModalIsOpen = false;

        $body.css({ overflow: 'visible' });

        gsap.timeline()
            .set($searchModal.get(0), { width: `${getFullWidth()}px` })
            .to($searchModal.get(0), {
                scale: 0.6,
                opacity: 0,
                duration: 0.75,
                onComplete() {
                    $searchModal.get(0).scrollTop = 0;
                    hideSearchResults();
                    Dispatch.emit(TOGGLE_SEARCH_MODAL_STATE);
                }
            })
            .set($searchModal.get(0), { clearProps: 'all' });
        try {
            if (currentBreakpoint >= MOBILE_MENU_BREAKPOINT) {
                Viewport.releaseTabbing();
            } else {
                Viewport.lockTabbing($('.menu')
                    .get(0));
                Viewport.enableTabbingOnElement($searchInput.get(0));
                $body.focus();
            }
        } catch (error) {
            //console.error(error);
        }
    };

    const onShowSearchResults = () => {
        gsap.timeline({
            onComplete() {
                Dispatch.emit(SEARCH_BAR_CLEAR);
            }
        })
            .to($searchResults.get(0), {
                opacity: 1,
                ease: 'Sine.easeOut',
                duration: 0.25
            })
            .to($btnCloseSearchModal.get(0), {
                opacity: 1,
                duration: 0.25,
                ease: 'Sine.easeOut'
            });

        try {
            Viewport.lockTabbing($searchModal.get(0));
        } catch (error) {
            //console.error(error);
        };
    };

    const openSearchModal = () => {
        Dispatch.emit(SEARCH_MODAL_OPEN);
    };

    const closeSearchModal = () => {
        Dispatch.emit(SEARCH_MODAL_CLOSE);
    };

    const onKeyUp = e => {
        if (e.keyCode === 27 && searchModalIsOpen) {
            closeSearchModal();
        }
    };

    const onBreakpoint = () => {
        currentBreakpoint = Viewport.breakpoint.size;
    };

    const init = () => {

        Dispatch.on(SEARCH_MODAL_OPEN, onOpenSearchModal);
        Dispatch.on(SEARCH_MODAL_CLOSE, onCloseSearchModal);
        Dispatch.on(SEARCH_RESULTS_SHOW, onShowSearchResults);
        Dispatch.on(SEARCH_RESULTS_HIDE, onHideSearchResults);

        Viewport.on('breakpoint', onBreakpoint);

        setupModalElements();

        $body.on('keyup', onKeyUp);

        $btnCloseSearchModal.on('click', e => {
            e.preventDefault();
            closeSearchModal();
        });

        $searchForm.on('submit', e => {
            e.preventDefault();
            openSearchModal();
            performSearch();
        });

    };

    const destroy = () => {
        Dispatch.off(SEARCH_MODAL_OPEN, onOpenSearchModal);
        Dispatch.off(SEARCH_MODAL_CLOSE, onCloseSearchModal);
        Dispatch.off(SEARCH_RESULTS_SHOW, onShowSearchResults);
        Dispatch.off(SEARCH_RESULTS_HIDE, onHideSearchResults);
        Viewport.off('breakpoint', onBreakpoint);
        $body.off('keyup', onKeyUp);
        $btnCloseSearchModal.off('click');
        $searchForm.off('submit');
    };

    return {
        init,
        destroy
    };

};
