/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */
export const MAIN_MENU_OPEN = 'MAIN_MENU_OPEN';
export const MAIN_MENU_CLOSE = 'MAIN_MENU_CLOSE';
export const SEARCH_OPEN = 'SEARCH_OPEN';
export const SEARCH_CLOSE = 'SEARCH_CLOSE';
export const SEARCH_MODAL_OPEN = 'SEARCH_MODAL_OPEN';
export const SEARCH_MODAL_CLOSE = 'SEARCH_MODAL_CLOSE';
export const SEARCH_RESULTS_SHOW = 'SEARCH_RESULTS_SHOW';
export const SEARCH_RESULTS_HIDE = 'SEARCH_RESULTS_HIDE';
export const MAIN_MENU_TOGGLE_FLAG = 'MAIN_MENU_TOGGLE_FLAG';
export const TOGGLE_SEARCH_MODAL_STATE = 'TOGGLE_SEARCH_MODAL_STATE';
export const SEARCH_BAR_SHOW = 'SEARCH_BAR_SHOW';
export const SEARCH_BAR_HIDE = 'SEARCH_BAR_HIDE';
export const SEARCH_BAR_CLEAR = 'SEARCH_BAR_CLEAR';
export const NEXT_SLIDE = 'NEXT_SLIDE';
export const PREVIOUS_SLIDE = 'PREVIOUS_SLIDE';
export const STOP_SLIDES = 'STOP_SLIDES';
export const START_SLIDES = 'START_SLIDES';
