import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { openShareWindow } from '../lib/helpers';

export default el => {

    const $el = $(el);

    const init = () => {
        $el.on('click', e => {
            e.preventDefault();
            openShareWindow(el.href);
        });
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
