import gsap from 'gsap';

export default el => {

    const delay = parseFloat(el.dataset.fade || 0);

    const init = () => {
        gsap.to(el, {
            opacity: 1,
            duration: 0.5,
            ease: 'Quad.easeOut',
            delay
        });
    };

    return {
        init
    };

};
