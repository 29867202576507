import gsap from 'gsap'
import Viewport from './core/Viewport'
import Components from './core/Components'
import GreenAudioPlayer from './core/GreenAudioPlayer'

gsap.defaults({ overwrite: 'auto' })

const init = () => {
  Viewport.init()
  Viewport.initTabbing()
  Components.init()
  GreenAudioPlayer.init({
    selector: '.audio-player',
    stopOthersOnPlay: true,
    enableKeystrokes: true
  })
}

require('doc-ready')(init)
