import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { NEXT_SLIDE, PREVIOUS_SLIDE, START_SLIDES, STOP_SLIDES } from '../lib/events';
import Viewport from '../core/Viewport';

const SLIDE_TIMEOUT = 6500;

export default el => {

    const $el = $(el);

    const $slides = $el.find('.hero__slide');
    const $nextButton = $el.find('.hero__slide-nav.-next');
    const $prevButton = $el.find('.hero__slide-nav.-prev');

    const slideCount = $slides.length;

    let delayId = null;
    let slideIndex = 0;
    let $currentSlide = $($slides.get(0));
    let $oldSlide = null;
    let $queueNext = $($slides.get(1));
    let $queuePrev = $($slides.get(slideCount - 1));

    let slideHeight = $el.height();
    let isStopped = false;
    let stopFlag = false;
    let isAnimating = false;

    const getSlides = () => {

        if (slideIndex > slideCount - 1) {
            slideIndex = 0;
        }

        if (slideIndex < slideCount - 1) {
            $queueNext = $($slides.get(slideIndex + 1));
        } else {
            $queueNext = $($slides.get(0));
        }

        if (slideIndex > 0) {
            $queuePrev = $($slides.get(slideIndex - 1));
        } else {
            $queuePrev = $($slides.get(slideCount - 1));
        }

    };

    const stopAnimation = () => {
        Dispatch.emit(STOP_SLIDES);
    };

    const onStopAnimation = () => {
        stopFlag = true;
    };

    const doStopAnimation = () => {
        if (delayId) {
            clearTimeout(delayId);
            delayId = null;
        }
        gsap.killTweensOf($slides.get());
    };

    const nextSlide = () => {
        Dispatch.emit(NEXT_SLIDE);
    };

    const prevSlide = () => {
        Dispatch.emit(PREVIOUS_SLIDE);
    };

    const delayAndStart = () => {
        delayId = setTimeout(() => {
            delayId = null;
            nextSlide();
        }, SLIDE_TIMEOUT);
    };

    const slideComplete = () => {
        gsap.set($oldSlide.get(0), { zIndex: 0 });
        isAnimating = false;
        getSlides();
        if (stopFlag) {
            doStopAnimation();
        } else {
            delayAndStart();
        }
    };

    const animateSlide = (slide, direction) => {

        isAnimating = true;

        const $slide = $(slide);
        const text = $slide.find('.hero__text.-blurb')
            .get(0);
        const link = $slide.find('.hero__text.-link')
            .get(0);

        let textOffset;

        if (direction === 'next') {
            textOffset = 100;
        } else if (direction === 'prev') {
            textOffset = -100;
        }

        gsap.timeline()
            .set($currentSlide.get(0), { zIndex: 1 })
            .set(slide, {
                zIndex: 2,
                opacity: 0
            })
            .set(text, {
                opacity: 0,
                x: `${textOffset}px`
            })
            .set(link, {
                opacity: 0,
                x: `${textOffset}px`
            })
            .to(slide, {
                autoAlpha: 1,
                duration: 0.5,
                ease: 'Sine.easeOut',
                onComplete: slideComplete
            }, 'anim')
            .to(text, {
                opacity: 1,
                duration: 1,
                x: 0,
                ease: 'Quint.easeInOut'
            }, 'anim')
            .to(link, {
                delay: 0.1,
                duration: 1,
                opacity: 1,
                x: 0,
                ease: 'Quint.easeInOut'
            }, 'anim');

        $oldSlide = $currentSlide;
        $currentSlide = $slide;

    };

    const onNextSlide = () => {
        animateSlide($queueNext.get(0), 'next');
        slideIndex += 1;
    };

    const onPrevSlide = () => {
        animateSlide($queuePrev.get(0), 'prev');
        if (slideIndex > 0) {
            slideIndex -= 1;
        } else {
            slideIndex = slideCount - 1;
        }
    };

    const slideNavClick = direction => {
        if (isAnimating) {
            return;
        }
        if (delayId) {
            clearTimeout(delayId);
            delayId = null;
        }
        if (direction === 'next') {
            nextSlide();
        } else if (direction === 'prev') {
            prevSlide();
        }
    };

    const onResize = () => {
        slideHeight = $el.height();
    };

    const startAnimation = () => {
        Dispatch.emit(START_SLIDES);
    };

    const onStartAnimation = () => {
        stopFlag = false;
        if (delayId) {
            clearTimeout(delayId);
            delayId = null;
        }
        delayAndStart();
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (!isStopped && scrollTop > slideHeight - 200) {
            stopAnimation();
            isStopped = true;
        } else if (isStopped && scrollTop < slideHeight - 199) {
            startAnimation();
            isStopped = false;
        }
    };

    const init = () => {

        Dispatch.on(NEXT_SLIDE, onNextSlide);
        Dispatch.on(PREVIOUS_SLIDE, onPrevSlide);
        Dispatch.on(STOP_SLIDES, onStopAnimation);
        Dispatch.on(START_SLIDES, onStartAnimation);

        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);

        delayAndStart();

        $nextButton.on('click', () => {
            slideNavClick('next');
        });

        $prevButton.on('click', () => {
            slideNavClick('prev');
        });

    };

    const destroy = () => {
        Dispatch.off(NEXT_SLIDE, onNextSlide);
        Dispatch.off(PREVIOUS_SLIDE, onPrevSlide);
        Dispatch.off(STOP_SLIDES, onStopAnimation);
        Dispatch.off(START_SLIDES, onStartAnimation);
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        doStopAnimation();
    };

    return {
        init,
        destroy
    };

};
