import gsap from 'gsap';
import $ from '../core/Dom';
import { getCookie, setCookie } from '../lib/helpers';

export default el => {

    const $el = $(el);

    const closeBanner = () => {
        gsap.to(el, {
            yPercent: 100,
            ease: 'Quart.easeOut',
            duration: 0.8,
            onComplete() {
                $el.css({ display: 'none' });
            }
        });
        setCookie('cookieBannerHidden', '1', 365);
    };

    const init = () => {
        $el.on('click', '[data-cookie-banner-close]', e => {
            e.preventDefault();
            closeBanner();
        });

        if (getCookie('cookieBannerHidden') !== '1') {
            $el.css({ display: 'block' });
            gsap.fromTo(el, {
                yPercent: 100
            }, {
                yPercent: 0,
                ease: 'Quart.easeOut',
                duration: 0.8,
                delay: 1
            });
        }
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
